import React from "react";
import popupStyles from "../styles/components/popup.module.scss";

import iconClose from "../images/icon-close.svg";

export default function Popup(props) {
    const { dataList, closePopup } = props;

    const itemRender = () => {
        return dataList.map((item) => {
            const { icon, description } = item;

            return (
                <li className={popupStyles.popupItem} key={icon}>
                    <img className={popupStyles.popupImg} src={ icon } alt={ description } />
                    <span className={popupStyles.popupDescription} dangerouslySetInnerHTML={{__html: description}}></span>
                </li>
            );
        });
    };

    return (
        <div className={popupStyles.popup}>
            <img className={popupStyles.popupClose} src={iconClose} alt="close" onClick={ closePopup }/>
            <ul className={popupStyles.popupList}>{ itemRender() }</ul>
        </div>
    );
}