import React, { useState } from "react";
import cx from 'classnames';
import Layout from "../components/layout";
import Button from "../components/Button";
import Popup from "../components/Popup";
import productPageStyles from "../styles/pages/product.module.scss";

import backgroundImage from "../images/bg-product.jpg";
import chocolateImage from "../images/product-chocolatte.png";
import cherryImage from "../images/product-cherry.png";
import cigaretteImage from "../images/product-cigarette.png";
import chocolateBackground from "../images/bg-popup-chocolate.jpg";
import cherryBackground from "../images/bg-popup-cherry.jpg";
import vanillaBackground from "../images/bg-popup-vanilla.jpg";
import icon01 from "../images/icon-01.svg";
import icon02 from "../images/icon-02.svg";
import icon03 from "../images/icon-03.svg";
import icon04 from "../images/icon-04.svg";
import icon05 from "../images/icon-05.svg";

const initialProduct = [
    {
        "title": "вишуканий шоколад",
        "imgUrl": chocolateImage,
        "bgPopup": chocolateBackground,
        "popupClass": "chocolate",
        "dataList": [
            {
                "icon": icon01,
                "description": "Преміальна сигарильна суміш із високоякісних сортів тютюну (Virginia, Burley, Oriental)"
            },
            {
                "icon": icon02,
                "description": "Пряний, солодкуватий сигарильний смак досягається завдяки <b>органічним екстрактам какао-бобів<b>"
            },
            {
                "icon": icon03,
                "description": "Спеціально розроблений сигарильний папір з подрібленого тютюнового листя"
            },
            {
                "icon": icon04,
                "description": "Вугільно-ацетатний фільтр у форматі KS для збалансованого та м’якого смаку"
            },
            {
                "icon": icon05,
                "description": "Високоякісний турбо-фільтр у форматі KS S, який формує потік диму для покращення смакових якостей"
            }
        ]
    },
    {
        "title": "соковита вишня",
        "imgUrl": cherryImage,
        "bgPopup": cherryBackground,
        "popupClass": "cherry",
        "dataList": [
            {
                "icon": icon01,
                "description": "Преміальна сигарильна суміш із високоякісних сортів тютюну (Virginia, Burley, Oriental)"
            },
            {
                "icon": icon02,
                "description": "Пряний, солодкуватий сигарильний смак досягається завдяки <b>органічним екстрактам вишні</b>"
            },
            {
                "icon": icon03,
                "description": "Спеціально розроблений сигарильний папір з подрібленого тютюнового листя"
            },
            {
                "icon": icon04,
                "description": "Вугільно-ацетатний фільтр у форматі KS для збалансованого та м’якого смаку"
            },
            {
                "icon": icon05,
                "description": "Високоякісний турбо-фільтр у форматі KS S, який формує потік диму для покращення смакових якостей"
            }
        ]
    },
    {
        "title": "солодка ваніль",
        "imgUrl": cigaretteImage,
        "bgPopup": vanillaBackground,
        "popupClass": "vanilla",
        "dataList": [
            {
                "icon": icon01,
                "description": "Преміальна сигарильна суміш із високоякісних сортів тютюну (Virginia, Burley, Oriental)"
            },
            {
                "icon": icon02,
                "description": "Пряний, солодкуватий сигарильний смак досягається завдяки <b>органічним екстрактам ванілі</b>"
            },
            {
                "icon": icon03,
                "description": "Спеціально розроблений сигарильний папір з подрібленого тютюнового листя"
            },
            {
                "icon": icon04,
                "description": "Вугільно-ацетатний фільтр у форматі KS для збалансованого та м’якого смаку"
            },
            {
                "icon": icon05,
                "description": "Високоякісний турбо-фільтр у форматі KS S, який формує потік диму для покращення смакових якостей"
            }
        ]
    }
];

export default function ProductPage() {
    const [isModalOpen, setModalOpen] = useState();

    const openPopup = (idx) => {
        setModalOpen(idx);
    };

    const closePopup = () => {
        setModalOpen(null);
    };

    const listProductRender = () => {
        return initialProduct.map((item, index) => {
            const { title, imgUrl, dataList, bgPopup, popupClass } = item;
            const backgroundPopup = { backgroundImage: `url(${bgPopup})` };

            return (
                <div className={productPageStyles.productItem} key={title}>
                    <div className={
                        cx(productPageStyles.productHolder,
                            {[productPageStyles.productHolderActive]: isModalOpen === index},
                            productPageStyles[popupClass])
                        }
                        style={backgroundPopup}>
                        <span className={productPageStyles.productCaption} onClick={ () => openPopup(index) } >{ title }</span>
                        <img
                            className={productPageStyles.productImage}
                            src={ imgUrl }
                            alt={ title }
                            onClick={ () => openPopup(index) }
                        />
                        <span className={productPageStyles.productText}  onClick={ () => openPopup(index) } >Детальніше</span>
                        <Popup dataList={ dataList } closePopup={ closePopup } />
                    </div>
                </div>
            );
        })
    };

    return (
        <Layout background={backgroundImage}>
            <div className={productPageStyles.productWrapper}>
                <h1 className={productPageStyles.productTitle}>А що смакує тобі?</h1>
                <div className={productPageStyles.productList}>
                    { listProductRender() }
                </div>
                <Button to="/video" label="Подивитись відео ще раз" />
            </div>
        </Layout>
    );
}